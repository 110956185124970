export default class HtmlSanitizer {
    constructor() {
        this.allowedTags = {
    		'A': true, 'ABBR': true, 'B': true, 'BLOCKQUOTE': true, 'BODY': true, 'BR': true, 'BUTTON': true, 'CENTER': true, 'CODE': true, 'DIV': true, 'EM': true, 'FONT': true,
    		'H1': true, 'H2': true, 'H3': true, 'H4': true, 'H5': true, 'H6': true, 'HR': true, 'I': true, 'IMG': true, 'LABEL': true, 'LI': true, 'OL': true, 'P': true, 'PRE': true,
    		'SMALL': true, 'SOURCE': true, 'SPAN': true, 'STRONG': true, 'SVG': true, 'TABLE': true, 'TBODY': true, 'TR': true, 'TD': true, 'TH': true, 'THEAD': true, 'UL': true, 'U': true, 'VIDEO': true
    	};
    	this.allowedAttributes = { 
    	    'align': true, 'color': true, 'controls': true, 'height': true, 'href': true, 'src': true, 'style': true, 'target': true, 'title': true, 'type': true, 
    	    'width': true, 'class': true, 'data-id': true, 'data-type': true, 'data-employee': true,
    	};
    	this.allowedCssStyles = { 'color': true, 'background-color': true, 'font-size': true, 'text-align': true, 'text-decoration': true, 'font-weight': true, 'gap': true, 'margin-top': true, 'margin': true, };
    	this.allowedSchemas = [ 'http:', 'https:', 'data:', 'm-files:', 'file:', 'ftp:' ];
    	this.allowedUriAttributes = { 'href': true, 'action': true };
    	this.allowedContentTags = { 'FORM': true };
    }
    
    
    
    sanitize(input) {
        input = input.trim();
        
		if (input === "") return ""; //to save performance and not create iframe
		if (input === "<br>") return ""; //firefox "bogus node" workaround

		let iframe = document.createElement('iframe');
		
		if (iframe['sandbox'] === undefined) {
			alert('Your browser does not support sandboxed iframes. Please upgrade to a modern browser.');
			return '';
		}
		
		iframe['sandbox'] = 'allow-same-origin';
		iframe.style.display = 'none';
		
		document.body.appendChild(iframe); // necessary so the iframe contains a document
		
		let iframedoc = iframe.contentDocument || iframe.contentWindow.document;
		
		if (iframedoc.body === null) iframedoc.write("<body></body>"); // null in IE
		
		iframedoc.body.innerHTML = input;
		
		const {allowedTags, allowedAttributes, allowedCssStyles, allowedSchemas, allowedUriAttributes, allowedContentTags, startsWithAny} = this;

		function makeSanitizedCopy(node) {
		    let newNode;
		    
			if (node.nodeType == Node.TEXT_NODE) {
			    newNode = node.cloneNode(true);
			} else if (node.nodeType === Node.ELEMENT_NODE && (allowedTags[node.tagName] || allowedContentTags[node.tagName])) {
				//remove useless empty spans (lots of those when pasting from MS Outlook)
				if ((node.tagName == "SPAN" || node.tagName == "B" || node.tagName == "I" || node.tagName == "U") && node.innerHTML.trim() === "" && node.classList.length === 0) {
					return document.createDocumentFragment();
				}

				if (allowedContentTags[node.tagName]) {
					newNode = iframedoc.createElement('DIV'); //convert to DIV
				} else {
					newNode = iframedoc.createElement(node.tagName);
				}

				for (let i = 0; i < node.attributes.length; i++) {
					const attr = node.attributes[i];
					
					if (allowedAttributes[attr.name]) {
						if (attr.name == "style") {
							for (let s = 0; s < node.style.length; s++) {
								const styleName = node.style[s];
								
								if (allowedCssStyles[styleName]) {
									newNode.style.setProperty(styleName, node.style.getPropertyValue(styleName));
								}
							}
						} else {
							if (allowedUriAttributes[attr.name]) { //if this is a "uri" attribute, that can have "javascript:" or something
								if (attr.value.indexOf(":") > -1 && !startsWithAny(attr.value, allowedSchemas)) {
								    continue;
								}
							}
							
							newNode.setAttribute(attr.name, attr.value);
						}
					}
				}
				
				for (let i = 0; i < node.childNodes.length; i++) {
					const subCopy = makeSanitizedCopy(node.childNodes[i]);
					newNode.appendChild(subCopy, false);
				}
			} else {
				newNode = document.createDocumentFragment();
			}
			
			return newNode;
		}

		const resultElement = makeSanitizedCopy(iframedoc.body);
		
		document.body.removeChild(iframe);
		
		return resultElement.innerHTML
			.replace(/<br[^>]*>(\S)/g, "<br>\n$1")
			.replace(/div><div/g, "div>\n<div"); //replace is just for cleaner code
	}
	
	startsWithAny(str, substrings) {
		for (let i = 0; i < substrings.length; i++) {
			if (str.indexOf(substrings[i]) === 0) {
				return true;
			}
		}
		return false;
	}
}
