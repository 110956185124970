window.currentTabSyncNotyf = null;

window.tabSync = new BroadcastChannel('bonnie_tab_sync');

window.tabSync.onmessage = function(event) {
    // this function will run whenever a different tab runs window.tabSync.postMessage('') (you can pass a message to the function but it is not used in this function)

    if (currentTabSyncNotyf) {
        window.notyf.dismiss(currentTabSyncNotyf);
    }

    currentTabSyncNotyf = window.notyf.error({
        message: 'The salon has been changed in another tab. Please <a class="text-white text-underlined" href="javascript:window.location.reload(true)">refresh your page</a>, or navigate to a new one.',
        duration: 0, // duration 0 means the notification will not dissapear until the user dismisses it
        dismissible: false // we don't want the user to dismiss the notification
    });

    // Assign an empty function to the jquery ajax methods. This will cancel any further ajax calls until the page is refreshed.
    $.post = $.get = $.ajax = () => {};
};
