import HtmlSanitizer from './HtmlSanitizer.js';
import moment from 'moment-timezone';

window._ = require('lodash');

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
    // include jquery for datatables and to make coreui functions a bit more straightforward
    window.$ = window.jQuery = require('jquery');

    // include datatables (bootstrap 4 version)
    require('datatables.net-bs4');
    // include datatables button extension (bootstrap 4 version)
    require('datatables.net-buttons-bs4');
    // include datatables rowreorder extension (bootstrap 4 version)
    require('datatables.net-rowreorder-bs4');
    // include datatables responsive extension (bootstrap 4 version)
    require('datatables.net-responsive-bs4');

    // add select2 to every page
    require('select2/dist/js/select2.full.min');

    // add image viewer
    require('viewerjs');
    require('jquery-viewer');

    window.moment = moment;

    require('tempusdominus-bootstrap-4');
} catch (e) {}

import { Notyf } from 'notyf';

window.notyf = new Notyf({
    dismissible: true,
    duration: 5000,
    position: {
        x: 'right',
        y: 'top'
    }
});

window.autosize = require('autosize');

require('holderjs');

window.sanitizer = new HtmlSanitizer;

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';
// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     encrypted: true
// });
